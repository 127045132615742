// PerceptualAttributesCategorization.jsx

import React from 'react';
import ConceptsCategorization from './drag-and-drop-files/ConceptsCategorization';
import availableConceptsData from './concepts-files/perceptualAttributes';

function PerceptualAttributesCategorization() {
  return (
    <ConceptsCategorization
      availableConceptsData={availableConceptsData}
      apiEndpoint="perceptual-attributes"
      title="perceptual_attributes_categorization"
      instructions="perceptual_attributes_categorization_instructions"
      consentText="perceptual_attributes_categorization_consent_text"
      />
  );
}

export default PerceptualAttributesCategorization;