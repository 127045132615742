import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import uiTextsEN from './en/uiTexts.json';
import uiTextsES from './es/uiTexts.json';
import uiTextsCAT from './cat/uiTexts.json';
import uiTextsFA from './fa/uiTexts.json';
import uiTextsFR from './fr/uiTexts.json';
import uiTextsFRCA from './fr-CA/uiTexts.json';
import uiTextsKN from './kn/uiTexts.json';
import uiTextsJP from './jp/uiTexts.json';
import uiTextsMY from './my/uiTexts.json';
import uiTextsPTBR from './pt-br/uiTexts.json'; 


import perceptionEN from './en/perception.json';
import perceptionES from './es/perception.json';
import perceptionCAT from './cat/perception.json';
import perceptionFA from './fa/perception.json';
import perceptionFR from './fr/perception.json';
import perceptionFRCA from './fr-CA/perception.json';
import perceptionKN from './kn/perception.json';
import perceptionJP from './jp/perception.json';
import perceptionMY from './my/perception.json';
import perceptionPTBR from './pt-br/perception.json'; 

import capevEN from './en/capev.json';
import capevES from './es/capev.json';
import capevCAT from './cat/capev.json';
import capevFA from './fa/capev.json';
import capevFR from './fr/capev.json';
import capevFRCA from './fr-CA/capev.json';
import capevKN from './kn/capev.json';
import capevJP from './jp/capev.json';
import capevMY from './my/capev.json';
import capevPTBR from './pt-br/capev.json';

import conceptsEN from './en/concepts.json';
import conceptsES from './es/concepts.json';
import conceptsCAT from './cat/concepts.json';
import conceptsKN from './kn/concepts.json';
import conceptsFA from './fa/concepts.json';
import conceptsFR from './fr/concepts.json';
import conceptsJP from './jp/concepts.json';
import conceptsMY from './my/concepts.json';
import conceptsPTBR from './pt-br/concepts.json'; 

import longTextsEN from './en/longTexts.json';
import longTextsES from './es/longTexts.json';
import longTextsCAT from './cat/longTexts.json';
import longTextsFR from './fr/longTexts.json';
import longTextsPTBR from './pt-br/longTexts.json';

import researchProjectsEN from './en/researchProjects.json';

// import other languages...

i18n
  .use(initReactI18next)
  .init({
    resources: {
      cat: {
        translation: {
          ...uiTextsCAT,
          ...longTextsCAT,
          ...perceptionCAT,
          ...conceptsCAT,
          ...capevCAT, 
        }
      },
      en: {
        translation: {
          ...uiTextsEN,
          ...longTextsEN,
          ...perceptionEN,
          ...conceptsEN, 
          ...capevEN, 
          ...researchProjectsEN,
        }
      },
      es: {
        translation: {
          ...uiTextsES,
          ...longTextsES,
          ...perceptionES,
          ...conceptsES,
          ...capevES, 
        }
      },
      fa: {
        translation: {
          ...uiTextsFA,
          ...capevFA,
          ...conceptsFA, 
          ...perceptionFA,
        }
      },
      fr: {
        translation: {
          ...uiTextsFR,
          ...longTextsFR,
          ...perceptionFR,
          ...conceptsFR,
          ...capevFR, 
        }
      },
      "fr-CA": {
        translation: {
          ...uiTextsFRCA,
          ...perceptionFRCA,
          ...capevFRCA,
        }
      },
      jp: {
        translation: {
          ...uiTextsJP,
          ...perceptionJP,
          ...conceptsJP,
          ...capevJP, 
        }
      },
      kn: {
        translation: {
          ...uiTextsKN,
          ...perceptionKN,
          ...conceptsKN,
          ...capevKN, 
        }
      },
      my: {
        translation: {
          ...uiTextsMY,
          ...perceptionMY,
          ...conceptsMY,
          ...capevMY, 
        }
      },
      "pt-BR": {
        translation: {
          ...uiTextsPTBR,
          ...longTextsPTBR,
          ...perceptionPTBR,
          ...conceptsPTBR,
          ...capevPTBR, 
        }
      },
      // ... other languages
    },
    lng: 'en', // Idioma per defecte
    fallbackLng: {
      "fr-CA": ["fr", "en"], // El francès del Quebec fa fallback al francès europeu, i després a l'anglès
      "fr": ["en"], // El francès europeu fa fallback a l'anglès
      default: ["en"], // Per la resta d'idiomes, el fallback és l'anglès
    },
    interpolation: {
      escapeValue: false, // Evita escapar HTML
    },
  });

export default i18n;