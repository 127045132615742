// currentProjects.js

const currentProjects = [
    {
      id: 1,
      title: "global_database_title",
      recruitmentCall: "global_database_recruitment_call",
      recruitmentDescription: "global_database_recruitment_description",
      inclusionCriteria: "global_database_inclusion_criteria",
      principalInvestigator: "Neus Calaf, PhD",
      researcherOrganization: "Autonomous University of Barcelona (Spain)",
      participantInformationLink: "/global-database-project-information-participants",
      informedConsentLink: "/global-database-project-informed-consent",
      participationLink: "/profile"
    },
    {
      id: 2,
      title: "perceptual_attributes_categorization_title",
      recruitmentCall: "perceptual_attributes_categorization_recruitment_call",
      recruitmentDescription: "perceptual_attributes_categorization_recruitment_description",
      inclusionCriteria: "perceptual_attributes_categorization_inclusion_criteria",
      principalInvestigator: "Neus Calaf, PhD",
      researcherOrganization: "Autonomous University of Barcelona (Spain)",
      participantInformationLink: "/perceptual-attributes-categorization-participants-information",
      informedConsentLink: "/perceptual-attributes-categorization-informed-consent",
      participationLink: "/perceptual-attributes-categorization"
    },
    {
      id: 3,
      title: "calidad_vocal_vs_cualidad_vocal_title",
      recruitmentCall: "calidad_vocal_vs_cualidad_vocal_recruitment_call",
      recruitmentDescription: "calidad_vocal_vs_cualidad_vocal_recruitment_description",
      inclusionCriteria: "calidad_vocal_vs_cualidad_vocal_inclusion_criteria",
      principalInvestigator: "Neus Calaf, PhD",
      researcherOrganization: "Autonomous University of Barcelona (Spain)",
      participantInformationLink: "#",
      informedConsentLink: "#",
      participationLink: "#"
    },
    {
      id: 4,
      title: "Identificación de retos para la adaptación del CAPE-Vr al español y catalán",
      recruitmentCall: "Blah, blah, blah",
      recruitmentDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer odio velit, posuere id sodales vel, feugiat semper enim. Proin ac enim imperdiet, malesuada est nec, egestas risus. Etiam et massa ante. Nullam sit amet posuere ex, eget tempor sapien. Cras egestas urna sed scelerisque porttitor. Etiam risus massa, malesuada a condimentum eget, pulvinar at dui. Maecenas suscipit metus a ex tempor viverra. Vivamus in diam posuere, aliquet justo sed, bibendum ex. Donec iaculis diam ac nibh cursus tristique.",
      inclusionCriteria: "Tal, tal i qual.",
      principalInvestigator: "Neus Calaf, PhD",
      researcherOrganization: "Universitat XYZ",
      participantInformationLink: "#",
      informedConsentLink: "#",
      participationLink: "#"
     },
    // Afegir més projectes aquí
  ];
  
  export default currentProjects;