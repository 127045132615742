// AvailableConcepts.jsx

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Droppable, Draggable } from '@hello-pangea/dnd';

function AvailableConcepts({ availableConcepts, setAvailableConcepts }) {
  const { t } = useTranslation();
  const [newConcept, setNewConcept] = useState('');

  const handleAddConcept = () => {
    if (!newConcept.trim()) return;
    const newConceptObj = {
      id: Date.now().toString(),
      label: newConcept.trim(),
    };
    setAvailableConcepts((prev) => [...prev, newConceptObj]);
    setNewConcept('');
  };

  const handleRemoveConcept = (id) => {
    setAvailableConcepts((prev) => prev.filter((concept) => concept.id !== id));
  };

  return (
    <div
      style={{
        border: '1px solid #ccc',
        padding: '10px',
        borderRadius: '4px',
        minHeight: '200px',
        flex: 1,
      }}
    >
      <h3>{t('available_concepts')}</h3>
      <Droppable droppableId="availableConcepts">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{
              minHeight: '150px',
              padding: '10px',
              backgroundColor: '#F4F9F9',
              borderRadius: '4px',
            }}
          >
            {availableConcepts.map((concept, index) => (
              <Draggable key={concept.id} draggableId={concept.id} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '5px',
                      margin: '5px 0',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      backgroundColor: '#fff',
                      ...provided.draggableProps.style,
                    }}
                  >
                    <span>{t(concept.label)}</span>
                    <button
                      onClick={() => handleRemoveConcept(concept.id)}
                      className="button-important-small"
                    >
                      ✕
                    </button>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <div style={{ marginTop: '10px' }}>
        <input
          type="text"
          value={newConcept}
          onChange={(e) => setNewConcept(e.target.value)}
          placeholder={t('add_new_concept')}
          className="input-field"
        />
        <button
          onClick={handleAddConcept}
          className="button-center"
        >
          {t('add')}
        </button>
      </div>
    </div>
  );
}

export default AvailableConcepts;