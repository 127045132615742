// AppRoutes.jsx

// UTILITIES
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from './auth-and-backend/auth/ProtectedRoute';
import ScrollToTop from './ScrollToTop';

/////////////////
///// OPEN //////
/////////////////

// REGISTRATION AND PROFILE
import ConfirmationPage from './auth-and-backend/auth/ConfirmationPage';
import EmailVerification from './auth-and-backend/auth/EmailVerification';
import PasswordResetComponent from './auth-and-backend/auth/PasswordResetComponent';
import LoginComponent from './auth-and-backend/auth/LoginComponent';

// OPEN APP VIEWS
import Header from './views/app-views/Header';
import Landing from './views/open-access/Landing';
import Footer from './views/app-views/Footer';

import LegalNotice from './views/open-access/LegalNotice';
import PrivacyPolicy from './views/open-access/PrivacyPolicy';

import ResearchAtAllVoiced from './views/open-access/ResearchAtAllVoiced';
import AboutUs from './views/open-access/AboutUs';
import WhoIsTalking from './views/open-access/WhoIsTalking';
import HowToCite from './views/open-access/HowToCite';

import ResourcesForTraining from './views/open-access/ResourcesForTraining';
import ResourcesForTeaching from './views/open-access/ResourcesForTeaching';
import ResourcesForResearch from './views/open-access/ResourcesForResearch';
import AllTheResources from './views/open-access/AllTheResources';

// OPEN RESOURCES
import RBSTraining from './training/RBSTraining/RBSTraining';
import OSTraining from './training/OSTraining/OSTraining';
import OSClassroom from './training/OSTraining/OSClassroom';
import CAPEVClassroom from './cape-v/CAPEVClassroom';
import CAPEVScholar from './cape-v/CAPEVScholar';
import CAPEVWorkshop from './cape-v/CAPEVWorkshop';
import CAPEVWorkshopStudent from './cape-v/cape-v-workshop/cape-v-workshop-students-files/CAPEVWorkshopStudent'; 

// OPEN RESEARCH PROJECTS COMPONENTS
import GlobalDatabaseProjectInformationParticipants from './open-research-hub/research-projects-files/GlobalDatabaseProjectInformationParticipants';
import GlobalDatabaseProjectInformedConsent from './open-research-hub/research-projects-files/GlobalDatabaseProjectInformedConsent';

//////////////////////
/// EVENT-SPECIFIC ///
//////////////////////

import ASHAConventionAnchors from './experiments/anchors/ASHAConventionAnchors';
import JoinAnchors from './experiments/anchors/JoinAnchors';
import TallerCAPEVWorkshopStudent from './cape-v/cape-v-workshop/cape-v-workshop-students-files/TallerCAPEVWorkshopStudent';

////////////////////////
/// PROTECTED ROUTES ///
////////////////////////

// STANDARD_USERS 
// Profile
import UserProfile from './auth-and-backend/auth/UserProfile';

// Resources
import CAPEVTraining from './cape-v/CAPEVTraining';
import GuessTheDiagnosis from './training/diagnosis/GuessTheDiagnosis';
import CAPEVWorkshopInstructor from './cape-v/cape-v-workshop/cape-v-workshop-instructor-files/CAPEVWorkshopInstructor'; 

// STUDENTS
import StudentDashboard from './views/authenticated-routes/StudentDashboard'; 

//////////////////
///// ADMIN //////
//////////////////

// PRIVATE USE
import TallerCAPEVWorkshopInstructor from './cape-v/cape-v-workshop/cape-v-workshop-instructor-files/TallerCAPEVWorkshopInstructor';

// CURRENT DEVELOPMENT
import AdminDashboard from './views/authenticated-routes/AdminDashboard';

import ResourcesToolbox from './views/open-access/ResourcesToolbox';
import OpenResearchHub from './open-research-hub/OpenResearchHub';
import CustomResearchSolutions from './custom-research-solutions/CustomResearchSolutions';

import PresetResources from './views/open-access/PresetResources';
import ResourceBuilder from './resource-builder/ResourceBuilder';

import CAPEVRParent from './cape-vr/CAPEVRParent';
import CAPEVRProtocol from './cape-vr/cape-vr-views/CAPEVRProtocol';
import ConceptualResources from './views/open-access/ConceptualResources';

import PerceptualAttributesCategorization from './drag-and-drop/PerceptualAttributesCategorization';
import PerceptualAttributesHierarchy from './drag-and-drop/PerceptualAttributesHierarchy';
import GeneralVoiceConceptsCategorization from './drag-and-drop/GeneralVoiceConceptsCategorization';
import GeneralVoiceConceptsHierarchy from './drag-and-drop/GeneralVoiceConceptsHierarchy';

import PerceptualAttributesCategorizationInformationParticipants from './open-research-hub/research-projects-files/PerceptualAttributesCategorizationInformationParticipants';
import PerceptualAttributesCategorizationInformedConsent from './open-research-hub/research-projects-files/PerceptualAttributesCategorizationInformedConsent';

// FUTURE DEVELOPMENT
import ResearchMethodologyGrid from './training/methodology/ResearchMethodologyGrid';
import ConceptManager from './tree/ConceptManager';
import EARV1 from './evaluation/ear-v1/EARV';
import EARV from './evaluation/ear-v/EARV';

// WHO KNOWS
import CapeVEntrenament from './cape-v/CapeVEntrenament';
import CapeVEntrenamiento from './cape-v/CapeVEntrenamiento';
import CAPEVClinic from './cape-v/CAPEVClinic';

import ConceptsFreeSortingTask from './concepts/free-sorting-task/ConceptsFreeSortingTask';
import Anchors from './experiments/anchors/Anchors';
import VASexperiment from './experiments/vas-experiment/VASexperiment';
import RandomVASexperiment from './experiments/random-vas-experiment/RandomVASexperiment';
import FreeSortingTask from './experiments/free-sorting-task/FreeSortingTask';
import CollectionsFreeSortingTask from './experiments/collections-free-sorting-task/CollectionsFreeSortingTask';
import TwoAFCexperiment from './experiments/2AFC-experiment/TwoAFCexperiment';
import Training from './training/training-in-three-phases/Training';

const AppRoutes = () => {
  return (
    <>
      <Header />
      <div className="flex-container">
        <div className="main-content">
          <div className="content-area">
            <ScrollToTop>
              <Routes>
                <Route path="/" element={<Landing />} />

                {/* //// */}
                {/* OPEN */}
                {/* //// */}

                {/* REGISTRATION AND PROFILE */}

                <Route path="/confirmation" element={<ConfirmationPage />} />
                <Route path="/verify-email" element={<EmailVerification />} />
                <Route path="/password-reset/:token" element={<PasswordResetComponent />} />
                <Route path="/login" element={<LoginComponent />} />

                {/* OPEN APP VIEWS */}

                <Route path="/legal-notice" element={<LegalNotice />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />

                <Route path="/research-at-all-voiced" element={<ResearchAtAllVoiced />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/citations" element={<WhoIsTalking />} />
                <Route path="/how-to-cite" element={<HowToCite />} />

                <Route path="/resources-for-autonomous-training" element={<ResourcesForTraining />} />
                <Route path="/resources-for-teaching-and-learning" element={<ResourcesForTeaching />} />
                <Route path="/resources-for-research" element={<ResourcesForResearch />} />
                <Route path="/all-the-resources" element={<AllTheResources />} />

                {/* OPEN RESOURCES */}

                <Route path="/rbs-training" element={<RBSTraining />} />
                <Route path="/os-training" element={<OSTraining />} />
                <Route path="/os-classroom" element={<OSClassroom />} />
                <Route path="/cape-v-classroom" element={<CAPEVClassroom />} />
                <Route path="/cape-v-scholar" element={<CAPEVScholar />} />
                <Route path="/cape-v-workshop" element={<CAPEVWorkshop />} />
                <Route path="/cape-v-workshop-student" element={<CAPEVWorkshopStudent />} />

                {/* OPEN RESEARCH PROJECTS COMPONENTS */}
                <Route path="/global-database-project-information-participants" element={<GlobalDatabaseProjectInformationParticipants />} />
                <Route path="/global-database-project-informed-consent" element={<GlobalDatabaseProjectInformedConsent />} />

                {/* ////////////// */}
                {/* EVENT-SPECIFIC */}
                {/* ////////////// */}

                <Route path="/2024-asha-convention-anchors" element={<ASHAConventionAnchors />} />
                <Route path="/join-anchors" element={<JoinAnchors />} />
                <Route path="/taller" element={<TallerCAPEVWorkshopStudent />} />

                {/* //////////////// */}
                {/* PROTECTED ROUTES */}
                {/* //////////////// */}

                {/* STANDARD_USERS */}

                {/* Profile */}
                <Route path="/profile" element={<ProtectedRoute allowedRoles={['admin', 'standard_user']}><UserProfile /></ProtectedRoute>} />

                {/* Resources */}
                <Route path="/cape-v-training" element={<ProtectedRoute allowedRoles={['admin', 'standard_user', 'student' ]}><CAPEVTraining /></ProtectedRoute>} />
                <Route path="/guess-the-diagnosis" element={<ProtectedRoute allowedRoles={['admin', 'standard_user', 'student' ]}><GuessTheDiagnosis /></ProtectedRoute>} />
                <Route path="/cape-v-workshop-instructor" element={<ProtectedRoute allowedRoles={['admin', 'standard_user']}><CAPEVWorkshopInstructor /></ProtectedRoute>} />

                {/* STANDARD_USERS */}
                <Route path="/student-dashboard" element={<ProtectedRoute allowedRoles={['admin', 'student' ]}><StudentDashboard /></ProtectedRoute>} />

                {/* ///// */}
                {/* ADMIN */}
                {/* ///// */}

                {/* PRIVATE USE */}
                <Route path="/cape-v-taller-vista-instructor" element={<ProtectedRoute allowedRoles={['admin']}><TallerCAPEVWorkshopInstructor /></ProtectedRoute>} />

                {/* CURRENT DEVELOPMENT */}
                <Route path="/admin-dashboard" element={<ProtectedRoute allowedRoles={['admin']}><AdminDashboard /></ProtectedRoute>} />

                <Route path="/resources" element={<ProtectedRoute allowedRoles={['admin']}><ResourcesToolbox /></ProtectedRoute>} />
                <Route path="/open-research-hub" element={<ProtectedRoute allowedRoles={['admin']}><OpenResearchHub /></ProtectedRoute>} />
                <Route path="/custom-research-solutions" element={<ProtectedRoute allowedRoles={['admin']}><CustomResearchSolutions /></ProtectedRoute>} />

                <Route path="/preset-resources" element={<ProtectedRoute allowedRoles={['admin']}><PresetResources /></ProtectedRoute>} />
                <Route path="/resource-builder" element={<ProtectedRoute allowedRoles={['admin']}><ResourceBuilder /></ProtectedRoute>} />

                <Route path="/cape-vr" element={<ProtectedRoute allowedRoles={['admin']}><CAPEVRParent /></ProtectedRoute>} />
                <Route path="/cape-vr-protocol" element={<ProtectedRoute allowedRoles={['admin']}><CAPEVRProtocol /></ProtectedRoute>} />
                <Route path="/conceptual-resources" element={<ProtectedRoute allowedRoles={['admin']}><ConceptualResources /></ProtectedRoute>} />

                <Route path="/perceptual-attributes-categorization" element={<ProtectedRoute allowedRoles={['admin', 'student' ]}><PerceptualAttributesCategorization /></ProtectedRoute>} />
                <Route path="/perceptual-attributes-hierarchy" element={<ProtectedRoute allowedRoles={['admin', 'student' ]}><PerceptualAttributesHierarchy /></ProtectedRoute>} />
                <Route path="/general-voice-concepts-categorization" element={<ProtectedRoute allowedRoles={['admin', 'student' ]}><GeneralVoiceConceptsCategorization /></ProtectedRoute>} />
                <Route path="/general-voice-concepts-hierarchy" element={<ProtectedRoute allowedRoles={['admin', 'student' ]}><GeneralVoiceConceptsHierarchy /></ProtectedRoute>} />

                <Route path="/perceptual-attributes-categorization-participants-information" element={<ProtectedRoute allowedRoles={['admin']}><PerceptualAttributesCategorizationInformationParticipants /></ProtectedRoute>} />
                <Route path="/perceptual-attributes-categorization-informed-consent" element={<ProtectedRoute allowedRoles={['admin']}><PerceptualAttributesCategorizationInformedConsent /></ProtectedRoute>} />
              
                {/* FUTURE DEVELOPMENT */}

                <Route path="/metodology-lab" element={<ProtectedRoute allowedRoles={['admin']}><ResearchMethodologyGrid /></ProtectedRoute>} />
                <Route path="/attributes-conceptualization-tree" element={<ProtectedRoute allowedRoles={['admin']}><ConceptManager /></ProtectedRoute>} />
                <Route path="/ear-v" element={<ProtectedRoute allowedRoles={['admin']}><EARV /></ProtectedRoute>} />
                <Route path="/ear-v1" element={<ProtectedRoute allowedRoles={['admin']}><EARV1 /></ProtectedRoute>} />

                {/* WHO KNOWS */}
                <Route path="/cape-v-entrenament" element={<ProtectedRoute allowedRoles={['admin', 'student' ]}><CapeVEntrenament /></ProtectedRoute>} />
                <Route path="/cape-v-entrenamiento" element={<ProtectedRoute allowedRoles={['admin']}><CapeVEntrenamiento /></ProtectedRoute>} />
                <Route path="/cape-v-clinic" element={<ProtectedRoute allowedRoles={['admin']}><CAPEVClinic /></ProtectedRoute>} />

                <Route path="/concepts" element={<ProtectedRoute allowedRoles={['admin', 'student' ]}><ConceptsFreeSortingTask /></ProtectedRoute>} />
                <Route path="/anchors" element={<ProtectedRoute allowedRoles={['admin']}><Anchors /></ProtectedRoute>} />
                <Route path="/free-sorting-task" element={<ProtectedRoute allowedRoles={['admin']}><FreeSortingTask /></ProtectedRoute>} />
                <Route path="/collections-free-sorting-task" element={<ProtectedRoute allowedRoles={['admin']}><CollectionsFreeSortingTask /></ProtectedRoute>} />
                <Route path="/2-afc" element={<ProtectedRoute allowedRoles={['admin']}><TwoAFCexperiment /></ProtectedRoute>} />
                <Route path="/vas-experiment" element={<ProtectedRoute allowedRoles={['admin']}><VASexperiment /></ProtectedRoute>} />
                <Route path="/random-vas-experiment" element={<ProtectedRoute allowedRoles={['admin']}><RandomVASexperiment /></ProtectedRoute>} />
                <Route path="/training-in-three-phases" element={<ProtectedRoute allowedRoles={['admin']}><Training /></ProtectedRoute>} />

                </Routes>
            </ScrollToTop>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AppRoutes;