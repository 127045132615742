// HierarchicalList.jsx

import React from 'react';
import { Droppable, Draggable } from '@hello-pangea/dnd';
import { useTranslation } from 'react-i18next';

function HierarchicalList({ items, removeItem }) {
  const { t } = useTranslation();

  // Calcula la indentació segons el role:
  const getIndentationLevel = (role, level) => {
    if (role === 'category') return '0px';
    if (role === 'subcategory') return '30px';
    if (role === 'sub-subcategory') return '60px';
    return `${level * 10 + 60}px`;
  };

  return (
    <Droppable droppableId="hierarchical-list">
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps} style={{ padding: '10px' }}>
          {items.map((item, index) => (
            <Draggable key={item.id} draggableId={item.id} index={index}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '5px',
                    margin: '5px 0',
                    border: '1px solid #209385',
                    borderRadius: '4px',
                    backgroundColor: '#fff',
                    marginLeft: getIndentationLevel(item.role, item.level),
                    // Apliquem color verd per categories, subcategories i sub‑subcategories
                    color: (item.role === 'category' || item.role === 'subcategory' || item.role === 'sub-subcategory') ? '#209385' : 'black',
                    // Negreta per category i subcategory; per sub‑subcategory només cursiu
                    fontWeight: (item.role === 'category' || item.role === 'subcategory') ? 'bold' : 'normal',
                    // Cursiu per subcategory i sub‑subcategory
                    fontStyle: (item.role === 'subcategory' || item.role === 'sub-subcategory') ? 'italic' : 'normal',
                    ...provided.draggableProps.style,
                  }}
                >
                  <span>{t(item.label)}</span>
                  <button onClick={() => removeItem(item.id)} className="button-important-small">✕</button>
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

export default HierarchicalList;