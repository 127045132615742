// GeneralVoiceConceptsHierarchy.jsx

import React from 'react';
import HierarchicalDragAndDrop from './hierarchical-drag-and-drop/HierarchicalDragAndDrop';
import availableConceptsData from './concepts-files/generalVoiceConcepts';

function GeneralVoiceConceptsHierarchy() {
  return (
    <HierarchicalDragAndDrop
      availableConceptsData={availableConceptsData}
      apiEndpoint="general-voice-concepts-hierarchy"
      title="general_voice_concepts_hierarchy_task"
      instructions="general_voice_concepts_hierarchy_instructions"
      consentText="general_voice_concepts_hierarchy_consent_text"
      />
  );
}

export default GeneralVoiceConceptsHierarchy;