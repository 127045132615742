// AboutUs.jsx

import React from 'react';
import { Trans } from 'react-i18next';

function AboutUs() {
  return (
    <div className="long-text-container">
      <section className="long-section">
        <Trans 
          i18nKey="about_us_whole_text"
          components={{
            h1: <h1>ABOUT US</h1>,  
            p: <p />,
            strong: <strong />,
            a: <a href="/citations">See who is talking about us</a>  // Contingut per evitar warning
          }}
        />
      </section>
    </div>
  );
}

export default AboutUs;