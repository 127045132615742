// GeneralVoiceConceptsCategorization.jsx

import React from 'react';
import ConceptsCategorization from './drag-and-drop-files/ConceptsCategorization';
import availableConceptsData from './concepts-files/generalVoiceConcepts';

function GeneralVoiceConceptsCategorization() {
  return (
    <ConceptsCategorization
      availableConceptsData={availableConceptsData}
      apiEndpoint="general-voice-concepts"
      title="general_voice_concepts_categorization_task"
      instructions="general_voice_concepts_categorization_instructions"
      consentText="general_voice_concepts_categorization_consent_text"
      />
  );
}

export default GeneralVoiceConceptsCategorization;