// ConsentModal.jsx

import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useTranslation, Trans } from 'react-i18next';

Modal.setAppElement('#root');

function ConsentModal({ 
  isOpen, 
  onRequestClose, 
  onConsentChange, 
  onSubmit, 
  consentText,
  fontSize = '1em' 
}) {
  const [accepted, setAccepted] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    setAccepted(null);
  }, [isOpen]);

  const handleConsentChange = (isAccepted) => {
    setAccepted(isAccepted);
    onConsentChange(isAccepted);
  };

  const handleSubmit = () => {
    if (accepted === null) {
      alert(t('please_select_option'));
    } else {
      onSubmit();
      onRequestClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={t('consent_modal_title')}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '500px',
          minWidth: '300px',
          padding: '20px',
          maxHeight: '80%',
          overflowY: 'auto',
          zIndex: 1000,
        },
        overlay: {
          zIndex: 999,
        },
      }}
    >
      <h2 style={{ fontSize: '1.2em', marginBottom: '20px' }}>{t('informed_consent')}</h2>
      <div style={{ fontSize, marginBottom: '20px' }}>
      <Trans i18nKey={consentText} components={{ a: <a href="/privacy-policy">{t('privacy_policy')}</a> }}/>
      </div>
      <div style={{ fontSize }}>
        <label style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <input
            type="radio"
            name="consent"
            checked={accepted === true}
            onChange={() => handleConsentChange(true)}
          />
          {t('i_agree')}
        </label>
        <label style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <input
            type="radio"
            name="consent"
            checked={accepted === false}
            onChange={() => handleConsentChange(false)}
          />
          {t('i_do_not_agree')}
        </label>
      </div>
      <div style={{ textAlign: 'center' }}>
        <button className="button-important" onClick={onRequestClose}>
          {t('cancel')}
        </button>
        <button className="button-center" onClick={handleSubmit} style={{ marginRight: '10px' }}>
          {t('submit')}
        </button>
      </div>
    </Modal>
  );
}

export default ConsentModal;