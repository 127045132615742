// CustomizedResource.jsx

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AudioManager from "../shared-files/tools-and-functions/AudioManager";

const CustomizedResource = ({ samplePreference, selectedAttributes, scales, onBack }) => {
  const { t } = useTranslation();

  // Estat inicial per als valors dels atributs seleccionats
  const [attributeValues, setAttributeValues] = useState([]);
  const [selectedSample, setSelectedSample] = useState(null);

  // Inicialitza els valors en carregar el component
  useEffect(() => {
    setAttributeValues(
      selectedAttributes.map((attr) => ({
        id: attr.id,
        label: t(attr.translationKey),
        absence: false, // Per Presence/Absence
        ordinalValue: null, // Per escales 0-3
        vasValue: 0, // Valor inicial per VAS
        attributeComment: "", // Valor inicial per als comentaris
        variability: null, // Per Variability
      }))
    );
  }, [selectedAttributes, t]);

  const handleAudioSelection = (sample) => {
    setSelectedSample(sample); // Guardar la mostra seleccionada
  };

  // Actualitza el valor d'un atribut concret
  const updateAttributeValue = (id, key, value) => {
    setAttributeValues((prev) =>
      prev.map((attr) =>
        attr.id === id ? { ...attr, [key]: value } : attr
      )
    );
  };

  return (
    <div className="custom-resource-form">
      <h2>{t("customized_resource")}</h2>

{/* Mostrar el gestor d'àudio segons la preferència */}
<AudioManager
        mode={samplePreference}
        onSampleSelected={handleAudioSelection}
      />

      {/* Mostra informació de la mostra seleccionada */}
      {selectedSample && (
        <div style={{ margin: "20px 0", textAlign: "center" }}>
          <p>
            {t("selected_sample")}: {selectedSample.sampleName}
          </p>
          <audio controls src={selectedSample.sampleLink}>
            Your browser does not support the audio element.
          </audio>
        </div>
      )}

      {/* Llista d'atributs */}
      <ul style={{ listStyle: "none", padding: "0" }}>
        {attributeValues.map((attr) => {
          const scale = scales.find((scale) => scale.id === attr.id);

          if (!scale) {
            return (
              <li key={attr.id} style={{ marginBottom: "20px" }}>
                <h3>{attr.label}</h3>
                <p style={{ color: "red" }}>{t("error_missing_scale")}</p>
              </li>
            );
          }

          return (
            <li key={attr.id} style={{ marginBottom: "30px" }}>
              <h3>{attr.label}</h3>

              {/* Presence/Absence */}
              {scale.absence && (
                <label className="custom-resource-checkbox-label-inline">
                  <input
                    type="checkbox"
                    checked={attr.absence}
                    onChange={(e) =>
                      updateAttributeValue(attr.id, "absence", e.target.checked)
                    }
                  />
                  {t("presence")}
                </label>
              )}

              {/* Ordinal Value (0-3 Scale) */}
              {scale.ordinalValue && (
                <div className="custom-resource-radio-buttons">
                  {["0_normal", "1_mild", "2_moderate", "3_severe"].map(
                    (key, index) => (
                      <label key={key}>
                        <input
                          type="radio"
                          name={`ordinal-${attr.id}`}
                          value={index}
                          checked={attr.ordinalValue === index}
                          onChange={() =>
                            updateAttributeValue(
                              attr.id,
                              "ordinalValue",
                              index
                            )
                          }
                        />
                        {t(key)}
                      </label>
                    )
                  )}
                </div>
              )}

              {/* VAS Scale */}
              {scale.value && (
                <div className="custom-resource-rating">
                  <input
                    type="range"
                    min="0"
                    max="100"
                    value={attr.vasValue}
                    className="custom-resource-slider"
                    onChange={(e) =>
                      updateAttributeValue(
                        attr.id,
                        "vasValue",
                        parseInt(e.target.value, 10)
                      )
                    }
                  />
                </div>
              )}

              {/* Variability Scale */}
              {scale.variability && (
                <div className="custom-resource-radio-buttons">
                  {["consistent", "intermittent"].map((option) => (
                    <label key={option}>
                      <input
                        type="radio"
                        name={`variability-${attr.id}`}
                        value={option}
                        checked={attr.variability === option}
                        onChange={() =>
                          updateAttributeValue(attr.id, "variability", option)
                        }
                      />
                      {t(option)}
                    </label>
                  ))}
                </div>
              )}

              {/* Comment Scale */}
              {scale.comment && (
                <textarea
                  className="custom-resource-textarea"
                  value={attr.attributeComment}
                  onChange={(e) =>
                    updateAttributeValue(
                      attr.id,
                      "attributeComment",
                      e.target.value
                    )
                  }
                  placeholder={t("write_your_comment")}
                />
              )}
            </li>
          );
        })}
      </ul>
      <button
        className="button-center"
        onClick={onBack}
      >
        {t("back_to_scales")}
      </button>
    </div>
  );
};

export default CustomizedResource;