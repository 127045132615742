// Header.jsx

import React, { useState, useEffect } from 'react'; 
import { Link } from 'react-router-dom';
import LanguageSwitcher from '../../locales/LanguageSwitcher';
import { useAuth } from '../../auth-and-backend/auth/authContext';
import AuthNavBar from '../app-views/AuthNavBar';
import LoginModal from '../../auth-and-backend/modals/LoginModal';
import SignUpModal from '../../auth-and-backend/modals/SignUpModal';
import LogoutComponent from '../../auth-and-backend/auth/LogoutComponent';
import { useTranslation } from 'react-i18next';

function Header() {
    const { user, logout } = useAuth(); // ✅ Ara només usem `useAuth()`
    const { t } = useTranslation();

    const [isLoginModalOpen, setLoginModalOpen] = useState(false);
    const [isSignUpModalOpen, setSignUpModalOpen] = useState(false);

    const openLoginModal = () => setLoginModalOpen(true);
    const closeLoginModal = () => setLoginModalOpen(false);
    const openSignUpModal = () => setSignUpModalOpen(true);
    const closeSignUpModal = () => setSignUpModalOpen(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const tokenData = JSON.parse(atob(token.split('.')[1]));
            const expirationDate = new Date(tokenData.exp * 1000);

            if (expirationDate < new Date()) {
                logout();
                alert(t('session_expired'));
            }
        }
    }, [logout, t]);

    return (
        <header className="header">
            <div>
                <Link to="/" className="link-style">
                    <h1>All-Voiced</h1>
                </Link>
            </div>

            {user && (
                <div>
                    <AuthNavBar userRole={user.role} /> {/* ✅ Eliminat `roleIds` */}
                </div>
            )}

            <div className="right-section">
                <div className="auth-links-container">
                    {!user ? (
                        <div className="auth-links">
                            <span onClick={openLoginModal} className="header-link">
                                {t('login')}
                            </span>
                            <span onClick={openSignUpModal} className="header-link">
                                {t('sign_up')}
                            </span>
                        </div>
                    ) : (
                        <div className="auth-links">
                            <Link to="/profile" className="header-link">{t('my_profile')}</Link>
                            <LogoutComponent />
                        </div>
                    )}
                </div>
                <LanguageSwitcher />
            </div>
            <LoginModal
                isOpen={isLoginModalOpen}
                onRequestClose={closeLoginModal}
                openSignUpModal={openSignUpModal}
            />
            <SignUpModal
                isOpen={isSignUpModalOpen}
                onRequestClose={closeSignUpModal}
                openLoginModal={openLoginModal}
            />
        </header>
    );
}

export default Header;