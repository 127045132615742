// StudentDashboard.jsx

import { useTranslation } from "react-i18next";

const resources = [
  { name: "Conceptes", route: "/general-voice-concepts-hierarchy", description: "Un exercici inicial per ordenar conceptes:" },
  // { name: "CAPE-V Entrenament", route: "/cape-v-entrenament", description: "CAPE-V amb mostres aleatòries d'entrenament en català i castellà:" },
  // { name: "CAPE-V Training", route: "/cape-v-training", description: "CAPE-V amb mostres aleatòries d'entrenament en anglès:" },
  // { name: "CAPE-V Classroom", route: "/cape-v-classroom", description: "CAPE-V amb mostres seleccionables d'entrenament en anglès:" },
  //{ name: "CAPE-Vr", route: "/cape-vr", description: "El CAPE-V revisat (CAPE-Vr), en construcció:" },
];

export default function StudentDashboard() {
  const { t } = useTranslation();

  return (
    <div style={{ padding: "2rem", textAlign: "center" }}>
      <h2>{t("student_dashboard")}</h2>
      <ul style={{ listStyle: "none", padding: 0 }}>
        {resources.map((resource, index) => (
          <li key={index} style={{ marginBottom: "0.5rem" }}>
            <div >{resource.description}</div>
            <a href={resource.route} >
              {resource.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}