// CAPEVRPreview.jsx

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../auth-and-backend/auth/authContext";
import LoginModal from "../../auth-and-backend/modals/LoginModal";
import AudioManager from "../../shared-files/tools-and-functions/AudioManager";

const CAPEVRPreview = ({ onSelect, onStartSession }) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [samplePreference, setSamplePreference] = useState("");
  const [selectedSample, setSelectedSample] = useState(null);
  const [error, setError] = useState(false);

  const handleSamplePreferenceChange = (value) => {
    setSamplePreference(value);
    setError(false);
    setSelectedSample(null);
  };

  const handleSampleSelected = (sample) => {
    setSelectedSample(sample);
  };

  // 🔹 Canvia a la vista del formulari
  const handleGoToForm = () => {
    if (!samplePreference || !selectedSample) {
      setError(true);
    } else {
      setError(false);
      onSelect(selectedSample);
    }
  };

  // 🔹 Canvia a la vista de sessió interactiva
  const handleStartSession = () => {
    if (!user) {
      setIsLoginModalOpen(true);
    } else {
      onStartSession();
    }
  };

  const renderLockIcon = () => {
    return user ? (
      <i className="fas fa-lock-open" style={{ color: "#a5d3ce", marginLeft: "10px" }}></i>
    ) : (
      <i className="fas fa-lock" style={{ color: "red", marginLeft: "10px" }}></i>
    );
  };

  return (
    <div style={{ textAlign: "center" }}>
      <h2>{t("cape_vr_protocol")}</h2>
      <p>{t("lorem_ipsum")}</p>
      <a href="/cape-vr-protocol" target="_blank" rel="noopener noreferrer" style={{ display: "block", marginBottom: "15px" }}>
        {t("view_protocol")}
      </a>

      <h2>{t("choose_sample_mode")}</h2>
      <p>{t("lorem_ipsum")}</p>

      <div style={{ marginBottom: "20px" }}>
        <select
          value={samplePreference}
          onChange={(e) => handleSamplePreferenceChange(e.target.value)}
          className="sample-selector"
        >
          <option value="">{t("select_your_sample_preference")}</option>
          <option value="random">{t("random_sample")}</option>
          <option value="manual">{t("manual_sample_selection")}</option>
          <option value="upload">{t("upload_sample")}</option>
        </select>
      </div>

      {(samplePreference === "random" || samplePreference === "manual" || samplePreference === "upload") && (
        <AudioManager mode={samplePreference} onSampleSelected={handleSampleSelected} />
      )}
      {error && <p style={{ textAlign: 'center', color: "red", marginTop: "10px" }}>{t("error_select_sample_mode")}</p>}

      <h2>{t("choose_session_mode")}</h2>
      <p>{t("lorem_ipsum")}</p>

      {/* 🔹 Botó per començar sessió interactiva (només per usuaris autenticats) */}
      <button onClick={handleStartSession} className="button-link restricted-link">
        {t("start_an_interactive_session")}
        <span className="restricted-marker">{renderLockIcon()}</span>
      </button>

      <LoginModal isOpen={isLoginModalOpen} onRequestClose={() => setIsLoginModalOpen(false)} />

      {/* 🔹 Botó per anar al formulari */}
      <button onClick={handleGoToForm} className="button-center" style={{ marginTop: "20px" }}>
        {t("go_to_the_capevr_form")}
      </button>
    </div>
  );
};

export default CAPEVRPreview;