// CAPEVWorkshop.jsx

import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './../locales/i18n';
import { useAuth } from './../auth-and-backend/auth/authContext';
import NavigationIcons from './../views/app-views/NavigationIcons';

function CAPEVWorkshop() {
    const { t } = useTranslation();
    const { user } = useAuth(); 
    const navigate = useNavigate();

    const renderLockIcon = (isRestricted) => (
        isRestricted ? (
            <i className="fas fa-lock" style={{ color: user ? '#a5d3ce' : 'red' }}></i>
        ) : null
    );

    return (
        <div className="main-content">
            <h1>{t('cape_v_workshop_title')}</h1>
            <p>
                <Trans i18nKey="cape_v_workshop_presentation" />
            </p>

            {/* CAPE-V Workshop (Instructor Access) */}
            <button 
                className="button-link workshop-button" 
                onClick={() => navigate('/cape-v-workshop-instructor')}
            >
                {t('instructor_access')}
                <span className="restricted-marker">{renderLockIcon(true)}</span>
            </button><br/>

            {/* CAPE-V Workshop (Student Access) */}
            <button 
                className="button-link workshop-button" 
                onClick={() => navigate('/cape-v-workshop-student')}
            >
                {t('student_access')}
            </button>
            
            <div className="navigation-container">
                <NavigationIcons />
            </div>
        </div>
    );    
}

export default CAPEVWorkshop;