// CAPEVRSession.jsx

import React from "react";
import { useTranslation } from "react-i18next";

const CAPEVRSession = () => {
  const { t } = useTranslation();

  return (
    <div className="capevr-session">
      <h2>{t("live_session")}</h2>
    </div>
  );
};

export default CAPEVRSession;