// HierarchicalDragAndDrop.jsx

import React, { useState } from 'react';
import { DragDropContext } from '@hello-pangea/dnd';
import { useTranslation, Trans } from 'react-i18next';
import HierarchicalList from './HierarchicalList';
import AddItemForm from './AddItemForm';
import HierarchyToJson, { convertToHierarchy } from './HierarchyToJson';
import { useUserData } from './../../auth-and-backend/auth/useUserData';
import { useSubmission } from './useSubmission';
import ConsentModal from './ConsentModal';
import Feedback from './Feedback';

function HierarchicalDragAndDrop({ 
  availableConceptsData, 
  apiEndpoint, 
  title, 
  instructions, 
  consentText 
}) {
  const { t } = useTranslation();
  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001/api';

  // 🔹 Recuperem info de l'usuari
  const { userId, sociodemographicData } = useUserData(apiUrl);
  const { showFeedback, otherUsersHierarchy, currentPage, totalPages, fetchFeedback, handleSubmit } = useSubmission(apiUrl, apiEndpoint);

  // 🔹 Estat per a la jerarquia enviada (per visualitzar-la o exportar-la)
  const [userHierarchy, setUserHierarchy] = useState([]);

  // 🔹 Definim el mapping dels nivells per cada role
  const levelMapping = {
    category: 0,
    subcategory: 1,
    'sub-subcategory': 2,
    concept: 3
  };

  const [items, setItems] = useState(
    availableConceptsData.map(concept => ({
      id: concept.id,
      label: concept.label,
      role: 'concept',
      level: levelMapping['concept']
    }))
  );

  // 🔹 Estat per al consentiment
  const [showConsentModal, setShowConsentModal] = useState(false);
  const [consentGiven, setConsentGiven] = useState(null);

  // 🔹 Inputs per afegir noves entrades
  const [newCategory, setNewCategory] = useState('');
  const [newSubcategory, setNewSubcategory] = useState('');
  const [newSubSubcategory, setNewSubSubcategory] = useState('');
  const [newConcept, setNewConcept] = useState('');
  const [observations, setObservations] = useState('');

  const addItem = (label, role) => {
    if (!label.trim()) return;
    const level = levelMapping[role];
  
    const newItem = {
      id: `item-${Date.now()}`,
      label: label.trim(),
      role,
      level,
    };
  
    setItems(prev => [newItem, ...prev]); // 🔹 Afegeix al principi de la llista
  
    // Neteja l’input després d’afegir
    if (role === 'category') setNewCategory('');
    if (role === 'subcategory') setNewSubcategory('');
    if (role === 'sub-subcategory') setNewSubSubcategory('');
    if (role === 'concept') setNewConcept('');
  };  

  const removeItem = (id) => {
    setItems(prev => prev.filter(item => item.id !== id));
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const newItems = [...items];
    const [movedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, movedItem);

    setItems(newItems);
  };

  // 🔹 Funció per enviar les dades:
  // Genera la jerarquia per a visualització/export, però envia la llista plana al backend.
  const submitResults = () => {
    const hierarchy = convertToHierarchy(items);
    setUserHierarchy(hierarchy); // Guardem la jerarquia per mostrar-la després
    // Enviem els items plans al backend perquè aquest mateix faci la conversió
    handleSubmit(userId, consentGiven, sociodemographicData, items, observations);
  };

  return (
    <div className="app-container">
      <h1 >{t(title)}</h1>

    <div className="hierarchical-container">
      {!showFeedback ? (
        <>
        <Trans i18nKey={instructions} /> <br/><br/>
          <div className="input-container">
            <h3 >{t('add_new_items_to_the_list')}</h3>
            <AddItemForm newItem={newCategory} setNewItem={setNewCategory} addItem={addItem} placeholder="new_category" buttonText="add_new_category" role="category" />
            <AddItemForm newItem={newSubcategory} setNewItem={setNewSubcategory} addItem={addItem} placeholder="new_subcategory" buttonText="add_new_subcategory" role="subcategory" />
            <AddItemForm newItem={newSubSubcategory} setNewItem={setNewSubSubcategory} addItem={addItem} placeholder="new_sub_subcategory" buttonText="add_new_sub_subcategory" role="sub-subcategory" />
            <AddItemForm newItem={newConcept} setNewItem={setNewConcept} addItem={addItem} placeholder="new_concept" buttonText="add_new_concept" role="concept" />
            <h3 >{t('drag_and_drop_the_items_to_build_a_hierarchy')}</h3>
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <HierarchicalList items={items} removeItem={removeItem} />
          </DragDropContext>
          <div>
            <label htmlFor="observations">{t('observations')}</label>
            <textarea id="observations" value={observations} onChange={(e) => setObservations(e.target.value)} placeholder={t('add_any_additional_observation_here')} className="hierarchy-textarea" />
          </div>

          <button className="button-center" onClick={() => setShowConsentModal(true)}>{t('submit')}</button>

          <ConsentModal isOpen={showConsentModal} onRequestClose={() => setShowConsentModal(false)} onConsentChange={setConsentGiven} onSubmit={submitResults} consentText={t(consentText)} />
        </>
      ) : (
        <>
          <Feedback userHierarchy={userHierarchy} otherUsersHierarchy={otherUsersHierarchy} fetchFeedback={fetchFeedback} currentPage={currentPage} totalPages={totalPages} />
          <div className="export-json-container">
            <HierarchyToJson items={items} />
          </div>                          
        </>
      )}
    </div>
      {/* 🔹 Botó per reiniciar l'activitat */}
      <div className="main-content">
            <br/>
            <button className="navigation-button" onClick={() => window.location.reload()}>
                {t('restart')}
            </button>
            <br/>
        </div>
    </div>
  );
}

export default HierarchicalDragAndDrop;