// PresetResources.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './../../locales/i18n';

function PresetResources() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const resources = [
        { title: t('resources_for_autonomous_training_title'), icon: '/images/training-icon.png', route: '/resources-for-autonomous-training' },
        { title: t('resources_for_teaching_and_learning_title'), icon: '/images/teaching-icon.png', route: '/resources-for-teaching-and-learning' },
        { title: t('resources_for_research_title'), icon: '/images/research-icon.png', route: '/resources-for-research' },
        { title: t('conceptual_resources'), icon: '/images/concepts-icon.png', route: '/conceptual-resources' },
    ];

    return (
        <div className="icon-button-container">
            {resources.map((resource, index) => (
                <div key={index} className="icon-container">
                    <button className="icon-button" onClick={() => navigate(resource.route)}>
                        <img src={resource.icon} alt={`${resource.title} Icon`} className="link-icon" />
                        <span className="link-text">{resource.title}</span>
                    </button>
                </div>
            ))}
        </div>
    );
}

export default PresetResources;