// InstabilitiesEvaluation.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';

function InstabilitiesEvaluation({
  selectedInstabilities,
  setSelectedInstabilities,
  instabilitiesValues,
  setInstabilitiesValues, // Afegim aquest
  otherInstabilityTag,
  setOtherInstabilityTag,
}) {
  const { t } = useTranslation();

  const handleCheckboxChange = (instability) => {
    if (selectedInstabilities.includes(instability)) {
      setSelectedInstabilities(selectedInstabilities.filter((item) => item !== instability));
      const updatedValues = { ...instabilitiesValues };
      delete updatedValues[instability]; // Esborra el valor
      setInstabilitiesValues(updatedValues);
      if (instability === 'otherInstability') {
        setOtherInstabilityTag('');
      }
    } else {
      setSelectedInstabilities([...selectedInstabilities, instability]);
      setInstabilitiesValues({
        ...instabilitiesValues,
        [instability]: 0, // Valor inicial
      });
    }
  };

  return (
    <div className="capevr-radio-group">
      <label className="capevr-radio-label">{t('instabilities')}:</label>
      <div className="capevr-checkboxes-horizontal">
        {[
          { value: 'aphonicBreak', translationKey: 'aphonic_break' },
          { value: 'pitchBreak', translationKey: 'pitch_break' },
          { value: 'pitchInstability', translationKey: 'pitch_instability' },
          { value: 'spasm', translationKey: 'spasm' },
          { value: 'tremor', translationKey: 'tremor' },
          { value: 'otherInstability', translationKey: 'other' },
        ].map(({ value, translationKey }) => (
          <label key={value} className="capevr-checkbox-label-inline">
            <input
              type="checkbox"
              name={value}
              value={value}
              checked={selectedInstabilities.includes(value)}
              onChange={() => handleCheckboxChange(value)}
            />
            {t(translationKey)}
            {value === 'otherInstability' && selectedInstabilities.includes(value) && (
              <input
                type="text"
                id="otherInstabilityTag"
                value={otherInstabilityTag}
                onChange={(e) => setOtherInstabilityTag(e.target.value)}
                placeholder={t('specify')}
                className="capevr-input-inline"
              />
            )}
          </label>
        ))}
      </div>
    </div>
  );
}

export default InstabilitiesEvaluation;