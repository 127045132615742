// VASexperiment.jsx
import React from 'react';
import { useAuth } from './../../auth-and-backend/auth/authContext.js';
import getUserSpecificData from './userSpecificData'; // Adjust the function name as necessary
import Attribute from './Attribute.jsx';
import { useTranslation } from 'react-i18next';
import './../../locales/i18n';

function VASexperiment() {
  const { user } = useAuth();
  const { t, i18n } = useTranslation();

  // Correct placement of useEffect
  React.useEffect(() => {
    // Function to execute when the language changes
    const handleLanguageChange = () => {
      console.log("Language changed to:", i18n.language);
      // This could trigger state updates or other actions needed on language change
    };

    i18n.on('languageChanged', handleLanguageChange);

    // Cleanup function to remove the listener
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]); // Dependency array to re-run the effect if i18n changes

  // Call getUserSpecificData() here, after the useEffect
  const data = getUserSpecificData();
  const userAttributes = data[user?.username]?.attributes;

  // Check and return early if no user or attributes
  if (!user || !userAttributes) {
    return <div>{t('no_data_available_for_this_user')}</div>;
  }

  return (
    <div className="App">
      <h1>{t('vas_experiment')}</h1>
      <p className="paragraph"> {t('vas_experiment_instructions')} <a href="mailto:info@all-voiced.com">info@all-voiced.com</a></p>
      {userAttributes.map(attribute => (
        <Attribute
          key={attribute.title}
          attributeData={attribute}
          username={user.username}
        />
      ))}
    </div>
  );
}

export default VASexperiment;
