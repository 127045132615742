// OpenResearchHub.jsx

import { useState } from "react";
import { useTranslation } from "react-i18next";
import ResearchProjectCard from "./ResearchProjectCard";
import currentProjects from "./currentProjects";
import ContactFormModal from "../auth-and-backend/modals/ContactFormModal";
import { useAuth } from "../auth-and-backend/auth/authContext";

function OpenResearchHub() {
    const { t } = useTranslation();
    const { user, setUser } = useAuth();
    const [selectedProject, setSelectedProject] = useState(null);
    const [isContactModalOpen, setContactModalOpen] = useState(false);

    const openContactModal = () => {
        setContactModalOpen(true);
    };

    const closeContactModal = () => {
        setContactModalOpen(false);
    };

    // Traduïm els projectes abans de passar-los a les targetes
    const translatedProjects = currentProjects.map((project) => ({
        ...project,
        title: t(project.title),
        recruitmentCall: t(project.recruitmentCall),
        recruitmentDescription: t(project.recruitmentDescription),
        inclusionCriteria: t(project.inclusionCriteria),
    }));
  
    return (
      <div className="container">
        <h1>Open Research Hub</h1>
        <p>{t("lorem_ipsum")}</p>
        <div className="projects-grid">
          {translatedProjects.map((project) => (
            <ResearchProjectCard
              user={user}
              setUser={setUser}
              key={project.id}
              project={project}
              isExpanded={selectedProject?.id === project.id}
              onSelect={() => setSelectedProject(selectedProject?.id === project.id ? null : project)}
            />
          ))}
        </div>
        <h2>{t("want_to_see_your_project_here")}</h2>
        <button 
            onClick={openContactModal} 
            className="button-center"
        >
            {t('contact')}
        </button>
        <ContactFormModal isOpen={isContactModalOpen} onRequestClose={closeContactModal} />
      </div>
    );
}
  
export default OpenResearchHub;