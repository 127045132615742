// useSubmission.js

import { useState, useCallback } from 'react';
import axios from 'axios';

export function useSubmission(apiUrl, apiEndpoint) {
  const [showFeedback, setShowFeedback] = useState(false);
  const [otherUsersCategorizations, setOtherUsersCategorizations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchFeedback = useCallback(async (page) => {
    try {
      const response = await axios.get(`${apiUrl}/${apiEndpoint}/feedback?page=${page}&limit=1`);
      setOtherUsersCategorizations(response.data.data);
      setTotalPages(response.data.pages);
      setCurrentPage(response.data.page);
    } catch (error) {
      console.error('Error fetching feedback data:', error);
    }
  }, [apiUrl, apiEndpoint]);

  const handleSubmit = async (userId, consentGiven, sociodemographicData, categories, observations) => {
    if (consentGiven) {
      const dataToSend = {
        userId,
        informedConsent: consentGiven,
        sociodemographicData,
        categories: categories.map((cat) => ({
          categoryName: cat.name,
          concepts: cat.concepts,
        })),
        observations: observations.trim(),
      };

      try {
        await axios.post(`${apiUrl}/${apiEndpoint}`, dataToSend);
        alert('Your results have been successfully submitted.');
      } catch (error) {
        console.error('Error submitting data:', error);
        alert('There was an error submitting your results. Please try again later.');
      }
    }
    setShowFeedback(true);
    fetchFeedback(1);
  };

  return { showFeedback, otherUsersCategorizations, currentPage, totalPages, fetchFeedback, handleSubmit };
}