// Feedback.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';

function Feedback({ feedbackData }) {
  const { t } = useTranslation();

  if (!feedbackData || feedbackData.length === 0) {
    return (
      <div className="highlighted-section">
        <h3>{t('no_feedback_available')}</h3>
        <p>{t('feedback_placeholder')}</p>
      </div>
    );
  }

  return (
    <div className="highlighted-section">
      {feedbackData.map((entry, index) => (
        <div key={index}>
          {entry.categories.map((category, catIndex) => (
            <div key={catIndex} style={{ marginBottom: '10px' }}>
              <strong>{t(category.categoryName)}</strong>
              <div>
                {category.concepts.map((concept, conceptIndex) => (
                  <div key={conceptIndex} style={{ marginLeft: '10px' }}>
                    {t(concept.label)}
                  </div>
                ))}
              </div>
            </div>
          ))}
          {entry.observations && (
            <div style={{ marginTop: '10px' }}>
              <strong>{t('observations')}:</strong>
              <p>{entry.observations}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default Feedback;