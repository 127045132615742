// AuthNavBar.jsx

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './../../locales/i18n';

  const AuthNavBar = ({ userRole }) => {
  const { t } = useTranslation();
  const [showSubMenu, setShowSubMenu] = useState(false);

  const handleSubMenu = () => {
    setShowSubMenu(!showSubMenu);
  };

  return (
    <nav>
      <ul>
        {userRole === 'admin' && (
          <>
            <li>
              <Link to="/admin-dashboard" className="nav-button">
                {t('admin_dashboard')}
              </Link>
            </li>
            <li>
              <Link to="/student-dashboard" className="nav-button">
                {t('student_dashboard')}
              </Link>
            </li>
            <li>
              <Link to="/cape-v-taller-vista-instructor" className="nav-button">
                {t('taller')}
              </Link>
            </li>
            <li onClick={handleSubMenu}>
              {t('admin')}
              <ul className={`submenu ${showSubMenu ? 'show' : ''}`}>
                <li><Link to="/cape-vr">{t('cape_vr')}</Link></li>
                <li><Link to="/cape-v-clinic">{t('cape_v_clinic_title')}</Link></li>
                <li><Link to="/cape-v-entrenament">CAPE-V Entrenament</Link></li>
                <li><Link to="/cape-v-entrenamiento">CAPE-V Entrenamiento</Link></li>
                <li><Link to="/training-in-three-phases">{t('training')} in three phases</Link></li>
                <li><Link to="/concepts">Conceptes</Link></li>
                <li><Link to="/2-afc">{t('2-AFC_test')}</Link></li>
                <li><Link to="/vas-experiment">{t('vas_experiment')}</Link></li>
                <li><Link to="/random-vas-experiment">{t('random_vas_experiment_title')}</Link></li>
                <li><Link to="/free-sorting-task">{t('free_sorting_task')}</Link></li>
                <li><Link to="/collections-free-sorting-task">{t('collections_free_sorting_task')}</Link></li>
                <li><Link to="/ear-v1">EAR-V1</Link></li>
                <li><Link to="/ear-v">EAR-V</Link></li>
                <li><Link to="/attributes-conceptualization-tree">Tree</Link></li>
                <li><Link to="/perceptual-attributes-categorization">Drag and Drop</Link></li>
                <li><Link to="/metodology-lab">Methodology Lab</Link></li>
                <li><Link to="/anchors">{t('anchors')}</Link></li>
              </ul>
            </li>
          </>
        )}
        {userRole === 'student' && (
          <li>
            <Link to="/student-dashboard" className="nav-button">
              {t('student_dashboard')}
            </Link>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default AuthNavBar;