import React, { useState } from "react";
import SampleModeSelection from "./SampleModeSelection";
import AttributesCustomization from "./AttributesCustomization";
import ScaleCustomization from "./ScaleCustomization";
import CustomizedResource from "./CustomizedResource";
import { useTranslation } from "react-i18next";

const ResourceBuilder = () => {
  const { t } = useTranslation();
  const [view, setView] = useState("sampleSelection");
  const [samplePreference, setSamplePreference] = useState("");
  const [selectedAttrs, setSelectedAttrs] = useState([]);
  const [scales, setScales] = useState([]);

  return (
    <div style={{ padding: "20px", width: "100%", margin: "0" }}>
      <h1 style={{ textAlign: "center" }}>{t("resource_builder")}</h1>

      {view === "sampleSelection" && (
        <SampleModeSelection onSelect={(preference) => {
          setSamplePreference(preference);
          setView("attributes");
        }} />
      )}

      {view === "attributes" && (
        <AttributesCustomization
          selectedAttributes={selectedAttrs}
          setSelectedAttributes={setSelectedAttrs}
          onProceed={() => setView("scales")}
          onBack={() => setView("sampleSelection")}
        />
      )}

      {view === "scales" && (
        <ScaleCustomization
          selectedAttrs={selectedAttrs}
          scales={scales}
          setScales={setScales}
          onBack={() => setView("attributes")}
          onProceed={() => setView("customizedResource")}
        />
      )}

      {view === "customizedResource" && (
        <CustomizedResource
          selectedAttributes={selectedAttrs}
          scales={scales}
          samplePreference={samplePreference}
          onBack={() => setView("scales")}
        />
      )}
    </div>
  );
};

export default ResourceBuilder;