// ResourcesToolbox.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './../../locales/i18n';

function ResourcesToolbox() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const resources = [
        { title: t('join_a_session'), icon: '/images/join-session-icon.png', route: '/join-a-session' },
        { title: t('preset_resources'), icon: '/images/preset-resources-icon.png', route: '/preset-resources' },
        { title: t('resource_builder'), icon: '/images/resources-builder-icon.png', route: '/resource-builder' },
    ];

    return (
        <div className="icon-button-container">
            {resources.map((resource, index) => (
                <div key={index} className="icon-container">
                    <button className="icon-button" onClick={() => navigate(resource.route)}>
                        <img src={resource.icon} alt={`${resource.title} Icon`} className="link-icon" />
                        <span className="link-text">{resource.title}</span>
                    </button>
                </div>
            ))}
        </div>
    );
}

export default ResourcesToolbox;