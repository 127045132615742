// SampleModeSelection.jsx

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const SampleModeSelection = ({ onSelect }) => {
  const { t } = useTranslation();
  const [samplePreference, setSamplePreference] = useState("");
  const [error, setError] = useState(false);

  const handleSamplePreferenceChange = (value) => {
    setSamplePreference(value);
    setError(false);
  };

  const handleNext = () => {
    if (!samplePreference) {
      setError(true);
    } else {
      setError(false);
      onSelect(samplePreference); // Passa la selecció al component pare
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <h2>{t("choose_sample_mode")}</h2>

      <div style={{ marginBottom: "20px" }}>
        <select
          value={samplePreference}
          onChange={(e) => handleSamplePreferenceChange(e.target.value)}
          className="sample-selector"
          style={{
            padding: "10px",
            fontSize: "16px",
            borderRadius: "4px",
            border: "1px solid #ccc",
          }}
        >
          <option value="">{t("select_your_sample_preference")}</option>
          <option value="random">{t("random_sample")}</option>
          <option value="manual">{t("manual_sample_selection")}</option>
          <option value="upload">{t("upload_sample")}</option>
        </select>
      </div>

      {error && <p style={{ color: "red", marginTop: "10px" }}>{t("error_select_sample_mode")}</p>}

      <button onClick={handleNext} className="button-center" style={{ marginTop: "20px" }}>
        {t("next_to_attribute_selection")}
      </button>
    </div>
  );
};

export default SampleModeSelection;