// HierarchyToJson.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';

// 🔹 Exportem la funció perquè pugui ser importada a altres components
export const convertToHierarchy = (items) => {
  const hierarchy = [];
  const stack = [];

  items.forEach(item => {
    const newNode = { name: item.label, type: item.role, children: [] };

    while (stack.length > 0 && stack[stack.length - 1].level >= item.level) {
      stack.pop();
    }

    if (stack.length === 0) {
      hierarchy.push(newNode);
    } else {
      stack[stack.length - 1].children.push(newNode);
    }

    stack.push({ ...newNode, level: item.level });
  });

  return hierarchy;
};

function HierarchyToJson({ items }) {
  const { t } = useTranslation();

  // Exporta l'arbre jeràrquic com un fitxer JSON descarregable
  const exportJson = () => {
    const hierarchy = convertToHierarchy(items);
    const json = JSON.stringify(hierarchy, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "hierarchical_structure.json";
    a.click();

    URL.revokeObjectURL(url);
  };

  return (
    <button className="button-center" onClick={exportJson}>
      {t('export_json')}
    </button>
  );
}

export default HierarchyToJson;