// YourCategorization.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';

function YourCategorization({ categories, observations }) {
  const { t } = useTranslation();
  return (
    <>
      <h2>Your Categorization</h2>
      <div className="highlighted-section">
        {categories.map((category, index) => (
          <div key={index} style={{ marginBottom: '10px' }}>
            <strong>{category.name}</strong>
            <div>
              {category.concepts.map((concept, conceptIndex) => (
                <div key={conceptIndex} style={{ marginLeft: '10px' }}>
                  {t(concept.label)}
                </div>
              ))}
            </div>
          </div>
        ))}
        {observations && (
          <div style={{ marginTop: '10px' }}>
            <strong>Observations:</strong>
            <p>{observations}</p>
          </div>
        )}
      </div>
    </>
  );
}

export default YourCategorization;