// PerceptualAttributesCategorization.jsx

import React from 'react';
import HierarchicalDragAndDrop from './hierarchical-drag-and-drop/HierarchicalDragAndDrop';
import availableConceptsData from './concepts-files/perceptualAttributes';

function PerceptualAttributesHierarchy() {
  return (
    <HierarchicalDragAndDrop
      availableConceptsData={availableConceptsData}
      apiEndpoint="perceptual-attributes-hierarchy"
      title="perceptual_attributes_hierarchy_task"
      instructions="perceptual_attributes_hierarchy_instructions"
      consentText="perceptual_attributes_hierarchy_consent_text"
      />
  );
}

export default PerceptualAttributesHierarchy;