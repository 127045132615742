// authContext.js

import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loadingUser, setLoadingUser] = useState(true);
    const [error, setError] = useState(null); 
    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001/api';

    const fetchUserData = useCallback(async (token) => {
        try {
            console.log("📡 Intentant obtenir dades de l'usuari amb token:", token);
            setLoadingUser(true); 

            const response = await axios.get(`${apiUrl}/users/me`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            let userData = response.data;

            // 🔍 Si `userData.role` és un ObjectId, fem una petició extra per obtenir el nom del rol
            if (userData.role && typeof userData.role === 'string' && userData.role.match(/^[0-9a-fA-F]{24}$/)) {
                console.log("🔍 `user.role` arriba com ObjectId. Demanant el nom...");
                const roleResponse = await axios.get(`${apiUrl}/users/${userData._id}/role`, {
                    headers: { Authorization: `Bearer ${token}` }
                });

                userData = { ...userData, role: roleResponse.data.role }; // Canviem l'ObjectId pel nom del rol
            }

            console.log("✅ Usuari rebut i actualitzat amb rol:", userData);
            setUser({ ...userData, token });
            setError(null);
        } catch (error) {
            console.error("❌ Error en `fetchUserData()`, detall complet:", error);
            if (error.response) {
                console.error("⚠️ Resposta del servidor:", error.response.status, error.response.data);
            }

            // ✅ Només fem `logout()` si el servidor ens diu explícitament que el token ha caducat
            if (error.response && error.response.status === 401) {
                console.log("🔴 Token caducat! Fent logout...");
                logout();
                window.location.href = '/login';
            } else {
                console.error("🔴 Error desconegut en `fetchUserData()`, però no fem `logout()`.");
                setError('Failed to fetch user data');
            }
        } finally {
            setLoadingUser(false); 
        }
    }, [apiUrl]);
    
    const login = async (email, password) => {
        try {
            const response = await axios.post(`${apiUrl}/users/login`, { email, password });
            const { token, user } = response.data;
            localStorage.setItem('token', token);
            setUser({ ...user, token });
            console.log('✅ User logged in:', { ...user, token });
            await fetchUserData(token);
        } catch (error) {
            console.error('❌ Error during login:', error);
            throw error; 
        }
    };

    const logout = () => {
        console.log("🚪 Logout executat.");
        setUser(null);
        localStorage.removeItem('token');
        setError(null);
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
    
        if (token) {
            console.log("🔄 Nova pestanya oberta: Recuperant `user` de `localStorage`...");
            fetchUserData(token);
        } else {
            console.log("⚠️ No hi ha token a `localStorage`, no es pot recuperar l'usuari.");
            setUser(null);
            setLoadingUser(false);
        }
    }, [fetchUserData]); // ✅ Ara inclou `fetchUserData` però està memorizat amb `useCallback`       

    useEffect(() => {
        const interceptor = axios.interceptors.response.use(
            response => response,
            error => {
                if (error.response && error.response.status === 401) {
                    console.log("🔴 Interceptor detecta token caducat. Fent logout...");
                    logout();
                    alert('Your session has expired. You have been logged out.');
                }
                return Promise.reject(error);
            }
        );

        return () => axios.interceptors.response.eject(interceptor);  
    }, []);

    return (
        <AuthContext.Provider value={{ user, setUser, login, logout, error, loadingUser }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;