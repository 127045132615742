// RecordingConditions.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';

function RecordingConditions({
  recordedAudio,
  setRecordedAudio,
  sessionType,
  setSessionType,
  recordingEnvironment,
  setRecordingEnvironment,
  recordingDeviceAndPlatform,
  setRecordingDeviceAndPlatform,
  mouthToMicDistance,
  setMouthToMicDistance,
}) {
  const { t } = useTranslation();

  return (
    <div className="capevr-conditions-section">
      {/* Audio Recorded */}
      <select
        id="recordedAudio"
        value={recordedAudio}
        onChange={(e) => setRecordedAudio(e.target.value)}
        className="input-field"
      >
        <option value="" disabled>
          {t('audio_recorded_yes_no')}
        </option>
        <option value="yes">{t('audio_recorded_yes')}</option>
        <option value="no">{t('audio_recorded_no')}</option>
      </select>

      {/* Session Type */}
      <select
        id="sessionType"
        value={sessionType}
        onChange={(e) => setSessionType(e.target.value)}
        className="input-field"
      >
        <option value="" disabled>
          {t('in_person_virtual')}
        </option>
        <option value="inPerson">{t('in_person')}</option>
        <option value="virtual">{t('virtual')}</option>
      </select>

      {/* Recording Environment */}
      <select
        id="recordingEnvironment"
        value={recordingEnvironment}
        onChange={(e) => setRecordingEnvironment(e.target.value)}
        className="input-field"
      >
        <option value="" disabled>
          {t('select_environment')}
        </option>
        <option value="clinicRoom">{t('environment_clinic_room')}</option>
        <option value="soundBooth">{t('environment_sound_booth')}</option>
        <option value="bedside">{t('environment_bedside')}</option>
        <option value="home">{t('environment_home')}</option>
      </select>

      {/* Recording Device and Platform */}
      <input
        type="text"
        id="recordingDeviceAndPlatform"
        value={recordingDeviceAndPlatform}
        onChange={(e) => setRecordingDeviceAndPlatform(e.target.value)}
        placeholder={t('recording_device_and_platform')}
        className="input-field"
      />

      {/* Mouth to Mic Distance */}
      <input
        type="number"
        id="mouthToMicDistance"
        value={mouthToMicDistance}
        onChange={(e) => setMouthToMicDistance(e.target.value)}
        placeholder={t('mouth_to_mic_distance')}
        className="input-field"
      />
    </div>
  );
}

export default RecordingConditions;