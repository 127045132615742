// PitchLoudnessEvaluation.jsx

// PitchLoudnessEvaluation.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';

function PitchLoudnessEvaluation({
  normalPitch,
  setNormalPitch,
  tooHighPitch,
  setTooHighPitch,
  tooLowPitch,
  setTooLowPitch,
  normalLoudness,
  setNormalLoudness,
  tooLoudLoudness,
  setTooLoudLoudness,
  tooSoftLoudness,
  setTooSoftLoudness,
}) {
  const { t } = useTranslation();

  const handlePitchSelection = (type) => {
    setNormalPitch({ ...normalPitch, presence: type === 'normal' });
    setTooHighPitch({ ...tooHighPitch, presence: type === 'tooHigh' });
    setTooLowPitch({ ...tooLowPitch, presence: type === 'tooLow' });
  };

  const handleLoudnessSelection = (type) => {
    setNormalLoudness({ ...normalLoudness, presence: type === 'normal' });
    setTooLoudLoudness({ ...tooLoudLoudness, presence: type === 'tooLoud' });
    setTooSoftLoudness({ ...tooSoftLoudness, presence: type === 'tooSoft' });
  };

  return (
    <div>
      {/* Pitch Evaluation */}
      <div className="capevr-radio-group">
        <label className="capevr-radio-label">{t('pitch')}:</label>
        <div className="capevr-radio-buttons-and-textarea">
          <div className="capevr-radio-buttons">
            <label>
              <input
                type="radio"
                name="pitch"
                checked={normalPitch.presence}
                onChange={() => handlePitchSelection('normal')}
              />
              {t('normal')}
            </label>
            <label>
              <input
                type="radio"
                name="pitch"
                checked={tooLowPitch.presence}
                onChange={() => handlePitchSelection('tooLow')}
              />
              {t('low')}
            </label>
            <label>
              <input
                type="radio"
                name="pitch"
                checked={tooHighPitch.presence}
                onChange={() => handlePitchSelection('tooHigh')}
              />
              {t('high')}
            </label>
          </div>

          {/* Comentari associat a Pitch */}
          {(normalPitch.presence || tooLowPitch.presence || tooHighPitch.presence) && (
            <textarea
              id="pitchComment"
              value={
                normalPitch.presence
                  ? normalPitch.attributeComment
                  : tooLowPitch.presence
                  ? tooLowPitch.attributeComment
                  : tooHighPitch.presence
                  ? tooHighPitch.attributeComment
                  : ''
              }
              onChange={(e) => {
                if (normalPitch.presence) {
                  setNormalPitch({ ...normalPitch, attributeComment: e.target.value });
                } else if (tooLowPitch.presence) {
                  setTooLowPitch({ ...tooLowPitch, attributeComment: e.target.value });
                } else if (tooHighPitch.presence) {
                  setTooHighPitch({ ...tooHighPitch, attributeComment: e.target.value });
                }
              }}
              placeholder={t('comment')}
              className="capevr-textarea"
            />
          )}
        </div>
      </div>

      {/* Loudness Evaluation */}
      <div className="capevr-radio-group">
        <label className="capevr-radio-label">{t('loudness')}:</label>
        <div className="capevr-radio-buttons-and-textarea">
          <div className="capevr-radio-buttons">
            <label>
              <input
                type="radio"
                name="loudness"
                checked={normalLoudness.presence}
                onChange={() => handleLoudnessSelection('normal')}
              />
              {t('normal')}
            </label>
            <label>
              <input
                type="radio"
                name="loudness"
                checked={tooSoftLoudness.presence}
                onChange={() => handleLoudnessSelection('tooSoft')}
              />
              {t('soft')}
            </label>
            <label>
              <input
                type="radio"
                name="loudness"
                checked={tooLoudLoudness.presence}
                onChange={() => handleLoudnessSelection('tooLoud')}
              />
              {t('loud')}
            </label>
          </div>

          {/* Comentari associat a Loudness */}
          {(normalLoudness.presence || tooSoftLoudness.presence || tooLoudLoudness.presence) && (
            <textarea
              id="loudnessComment"
              value={
                normalLoudness.presence
                  ? normalLoudness.attributeComment
                  : tooSoftLoudness.presence
                  ? tooSoftLoudness.attributeComment
                  : tooLoudLoudness.presence
                  ? tooLoudLoudness.attributeComment
                  : ''
              }
              onChange={(e) => {
                if (normalLoudness.presence) {
                  setNormalLoudness({ ...normalLoudness, attributeComment: e.target.value });
                } else if (tooSoftLoudness.presence) {
                  setTooSoftLoudness({ ...tooSoftLoudness, attributeComment: e.target.value });
                } else if (tooLoudLoudness.presence) {
                  setTooLoudLoudness({ ...tooLoudLoudness, attributeComment: e.target.value });
                }
              }}
              placeholder={t('comment')}
              className="capevr-textarea"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default PitchLoudnessEvaluation;