// InconsistenciesEvaluation.jsx

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

function InconsistenciesEvaluation({
  vowelsInconsistency,
  setVowelsInconsistency,
  sentencesInconsistency,
  setSentencesInconsistency,
  extemporaneousSpeechInconsistency,
  setExtemporaneousSpeechInconsistency,
}) {
  const { t } = useTranslation();

  // Estat per controlar la selecció inicial
  const [selectionMade, setSelectionMade] = useState(null);

  const handlePresenceChange = (presence) => {
    setSelectionMade(true); // Marca que s'ha fet una selecció
    setVowelsInconsistency({ ...vowelsInconsistency, presence });
    setSentencesInconsistency({ ...sentencesInconsistency, presence });
    setExtemporaneousSpeechInconsistency({ ...extemporaneousSpeechInconsistency, presence });
  };

  const isAnyPresent =
    vowelsInconsistency.presence ||
    sentencesInconsistency.presence ||
    extemporaneousSpeechInconsistency.presence;

  return (
    <div className="capevr-radio-group">
      <label className="capevr-radio-label">{t('inconsistencies')}:</label>
      <div className="capevr-radio-buttons-and-textarea">
        {/* Radio Buttons */}
        <div className="capevr-radio-buttons">
          <label>
            <input
              type="radio"
              name="inconsistencies"
              checked={selectionMade === true && !isAnyPresent}
              onChange={() => handlePresenceChange(false)}
            />
            {t('none')}
          </label>
          <label>
            <input
              type="radio"
              name="inconsistencies"
              checked={selectionMade === true && isAnyPresent}
              onChange={() => handlePresenceChange(true)}
            />
            {t('present_describe')}:
          </label>
        </div>

        {/* Textareas només si "Present" està seleccionat */}
        {vowelsInconsistency.presence && (
          <div className="capevr-textareas">
            <textarea
              value={vowelsInconsistency.attributeComment || ''}
              onChange={(e) =>
                setVowelsInconsistency({
                  ...vowelsInconsistency,
                  attributeComment: e.target.value,
                })
              }
              placeholder={t('vowels')}
              className="capevr-textarea"
            />
          </div>
        )}

        {sentencesInconsistency.presence && (
          <div className="capevr-textareas">
            <textarea
              value={sentencesInconsistency.attributeComment || ''}
              onChange={(e) =>
                setSentencesInconsistency({
                  ...sentencesInconsistency,
                  attributeComment: e.target.value,
                })
              }
              placeholder={t('sentences')}
              className="capevr-textarea"
            />
          </div>
        )}

        {extemporaneousSpeechInconsistency.presence && (
          <div className="capevr-textareas">
            <textarea
              value={extemporaneousSpeechInconsistency.attributeComment || ''}
              onChange={(e) =>
                setExtemporaneousSpeechInconsistency({
                  ...extemporaneousSpeechInconsistency,
                  attributeComment: e.target.value,
                })
              }
              placeholder={t('extemporaneous_speech')}
              className="capevr-textarea"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default InconsistenciesEvaluation;