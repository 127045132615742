// StandardVocalAttributesRating.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import VocalAttributeRatingInput from './VocalAttributeRatingInput';

function StandardVocalAttributesRating({
  overallSeverity,
  setOverallSeverity,
  roughness,
  setRoughness,
  breathiness,
  setBreathiness,
  strain,
  setStrain,
}) {
  const { t } = useTranslation();

  return (
    <div className="capevr-rating">
      {/* Etiquetes per als extrems de la escala */}
      <div className="capevr-scale-container">
        <span className="capevr-scale-label capevr-scale-left">{t('normal')}</span>
        <span className="capevr-scale-label capevr-scale-right">{t('extreme')}</span>
      </div>
      {/* Els 4 sliders del CAPE-Vr */}
      <VocalAttributeRatingInput
        label={t('overall_severity')}
        value={overallSeverity}
        setValue={setOverallSeverity}
        index={0}
      />
      <VocalAttributeRatingInput
        label={t('roughness')}
        value={roughness}
        setValue={setRoughness}
        index={1}
      />
      <VocalAttributeRatingInput
        label={t('breathiness')}
        value={breathiness}
        setValue={setBreathiness}
        index={2}
      />
      <VocalAttributeRatingInput
        label={t('strain')}
        value={strain}
        setValue={setStrain}
        index={3}
      />
    </div>
  );
}

export default StandardVocalAttributesRating;