// generalVoiceConcepts.js

const availableConcepts = [
  { id: '1', label: 'aerodynamic_measures' },
  { id: '2', label: 'asthenia' },
  { id: '3', label: 'avqi' },
  { id: '4', label: 'breathiness' },
  { id: '5', label: 'breathing_techniques' },
  { id: '6', label: 'cape_v' },
  { id: '7', label: 'cough_reflex_testing' },
  { id: '8', label: 'decibel' },
  { id: '9', label: 'egg' },
  { id: '10', label: 'frequency' },
  { id: '11', label: 'fry' },
  { id: '12', label: 'glottal_efficiency' },
  { id: '13', label: 'grbas' },
  { id: '14', label: 'high_speed_videoendoscopy' },
  { id: '15', label: 'hnr' },
  { id: '16', label: 'intensity' },
  { id: '17', label: 'jitter' },
  { id: '18', label: 'laryngitis' },
  { id: '19', label: 'lax_vox' },
  { id: '20', label: 'loudness' },
  { id: '21', label: 'manual_laryngeal_therapy' },
  { id: '22', label: 'maximum_phonation_time' },
  { id: '23', label: 'myofunctional_therapy' },
  { id: '24', label: 'overall_severity' },
  { id: '25', label: 'phonation_threshold_pressure' },
  { id: '26', label: 'pitch' },
  { id: '27', label: 'pitch_range' },
  { id: '28', label: 'postural_reeducation' },
  { id: '29', label: 'resonant_voice_therapy' },
  { id: '30', label: 'roughness' },
  { id: '31', label: 's_z_ratio' },
  { id: '32', label: 'shimmer' },
  { id: '33', label: 'spasmodic_dysphonia' },
  { id: '34', label: 'spectrogram' },
  { id: '35', label: 'strain' },
  { id: '36', label: 'subglottic_pressure' },
  { id: '37', label: 'timbre' },
  { id: '38', label: 'tremor' },
  { id: '39', label: 'vapp' },
  { id: '40', label: 'vhi' },
  { id: '41', label: 'videolaryngostroboscopy' },
  { id: '42', label: 'visual_and_auditory_feedback' },
  { id: '43', label: 'vocal_abuse' },
  { id: '44', label: 'vocal_cysts' },
  { id: '45', label: 'vocal_efficiency' },
  { id: '46', label: 'vocal_fatigue' },
  { id: '47', label: 'vocal_fold_paralysis' },
  { id: '48', label: 'vocal_hygiene' },
  { id: '49', label: 'vocal_loading_test' },
  { id: '50', label: 'vocal_misuse' },
  { id: '51', label: 'vocal_nodules' },
  { id: '52', label: 'vocal_polyps' },
  { id: '53', label: 'vocal_quality' },
  { id: '54', label: 'vocal_sirens' },
  { id: '55', label: 'vocal_warm_up_exercises' },
  { id: '56', label: 'voice_projection' },
  { id: '57', label: 'voice_range_profile' },
  { id: '58', label: 'voice_therapy' },
  { id: '59', label: 'voiss' },
  { id: '60', label: 'volume_dynamics' },
  ];
    
    export default availableConcepts;
    