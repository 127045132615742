// ResearchProjectCard.jsx

import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:5001/api";

export function ResearchProjectCard({ project, isExpanded, onSelect, user, setUser }) {
    const { t } = useTranslation();
    const [checkedItems, setCheckedItems] = useState({
      inclusionCriteria: false,
      informationAndConsentReadAndAccepted: false,
    });
    const [errorMessage, setErrorMessage] = useState("");
    const [isEnrolled, setIsEnrolled] = useState(user?.acceptResearchUsage || false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!user || !user.token) {
            console.warn("User object is missing or incomplete");
            return;
        }
    }, [user]);

    const handleCheckboxChange = (event) => {
      setCheckedItems({
        ...checkedItems,
        [event.target.name]: event.target.checked,
      });
      setErrorMessage("");
    };

    const toggleEnrollment = async () => {
        if (project.id !== 1) return;
        if (!user || !user._id || !user.token) {
            console.error("User object is missing required properties.");
            return;
        }

        setLoading(true);
        try {
            const newEnrollmentStatus = !isEnrolled;
            
            const response = await axios.put(`${apiUrl}/users/${user._id}/update-consent`, {
                acceptResearchUsage: newEnrollmentStatus,
            }, {
                headers: { Authorization: `Bearer ${user.token}` },
            });

            if (response.status === 200) {
                setIsEnrolled(newEnrollmentStatus);
                setUser({ ...user, acceptResearchUsage: newEnrollmentStatus }); // Actualitzem `user`
            } else {
                console.error("Error updating enrollment status");
            }
        } catch (error) {
            console.error("Request failed", error);
        } finally {
            setLoading(false);
        }
    };

    return (
      <div className={`research-card ${isExpanded ? "expanded" : ""}`}>
        <h2>{project.title}</h2>
        <h3 className="project-subtitle">{project.recruitmentCall}</h3>
        <p>{project.recruitmentDescription}</p>

        {isExpanded && (
          <div className="project-details">
            <p><strong>{t("principal_investigator")}:</strong> {project.principalInvestigator}</p>
            <p><strong>{t("organization")}:</strong> {project.researcherOrganization}</p>
            <p><strong>{t("inclusion_criteria")}:</strong> {project.inclusionCriteria}</p>

            <a href={project.participantInformationLink} target="_blank" rel="noopener noreferrer">{t("participant_information")}</a><br/>
            <a href={project.informedConsentLink} target="_blank" rel="noopener noreferrer">{t("informed_consent")}</a><br/><br/>

            {project.id !== 1 && (
              <div className="checkboxes">
                <label>
                  <input
                    type="checkbox"
                    name="inclusionCriteria"
                    checked={checkedItems.inclusionCriteria}
                    onChange={handleCheckboxChange}
                  />
                  {t("i_meet_the_inclusion_criteria")}
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="informationAndConsentReadAndAccepted"
                    checked={checkedItems.informationAndConsentReadAndAccepted}
                    onChange={handleCheckboxChange}
                  />
                  {t("i_have_read_and_accept_the_participant_information_and_the_informed_consent")}
                </label>
              </div>
            )}
            
            {errorMessage && <p className="error-message">{errorMessage}</p>}

            {project.id === 1 && user && user.token && (
              <div className="text-red">
                <p>
                  {isEnrolled 
                    ? t("you_are_enrolled") 
                    : t("you_are_not_enrolled")}
                </p>
                <button 
                  className="button-participate" 
                  onClick={toggleEnrollment} 
                  disabled={loading}
                >
                  {isEnrolled ? t("opt_out") : t("participate")}
                </button>
              </div>
            )}
                  {project.id !== 1 && (
              <a href={project.participationLink} className="button-participate" target="_blank" rel="noopener noreferrer">
                {t("participate")}
              </a>
            )}
          </div>
        )}

        <div className="expand-toggle" onClick={onSelect}></div>
      </div>
    );
}

export default ResearchProjectCard;