// AllTheResources.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './../../auth-and-backend/auth/authContext';
import NavigationIcons from './../app-views/NavigationIcons';

function AllTheResources() {
    const { t } = useTranslation();
    const { user } = useAuth();
    const navigate = useNavigate();

    const renderLockIcon = (loginRequired) => (
        loginRequired ? (
            <i className="fas fa-lock" style={{ color: user ? '#a5d3ce' : 'red', marginRight: '8px' }}></i>
        ) : null
    );

    const resources = [
        { title: t('cape_v_training_title'), sampleAccess: t('random'), mainObjective: t('autonomous_training'), description: t('practice_evaluating_random_samples_at_your_own_pace'), link: '/cape-v-training', loginRequired: true },
        { title: t('overall_severity'), sampleAccess: t('random'), mainObjective: t('autonomous_training'), description: t('practice_evaluating_random_samples_at_your_own_pace'), link: '/os-training', loginRequired: false },
        { title: t('roughness_breathiness_strain'), sampleAccess: t('random'), mainObjective: t('autonomous_training'), description: t('practice_evaluating_random_samples_at_your_own_pace'), link: '/rbs-training', loginRequired: false },
        { title: t('guess_the_diagnosis_title'), sampleAccess: t('random'), mainObjective: t('autonomous_training'), description: t('play_guessing_the_vocal_diagnosis_from_random_samples'), link: '/guess-the-diagnosis', loginRequired: true },
        { title: t('cape_v_workshop_student_access'), sampleAccess: t('selectable'), mainObjective: t('teaching_and_learning'), description: t('join_a_group_session_to_share_compare_and_discuss_evaluations'), link: '/cape-v-workshop-student', loginRequired: false },
        { title: t('cape_v_workshop_instructor_access'), sampleAccess: t('selectable'), mainObjective: t('teaching_and_learning'), description: t('create_a_group_session_to_gather_compare_and_discuss_evaluations'), link: '/cape-v-workshop-instructor', loginRequired: true },
        { title: t('cape_v_classroom_title'), sampleAccess: t('selectable'), mainObjective: t('teaching_and_learning'), description: t('evaluate_instructor_s_selected_sample_and_get_feedback'), link: '/cape-v-classroom', loginRequired: false },
        { title: t('cape_v_scholar_title'), sampleAccess: t('uploadable'), mainObjective: t('teaching_learning_research'), description: t('upload_and_evaluate_your_own_samples'), link: '/cape-v-scholar', loginRequired: false },
    ];

    return (
        <div className="main-content">
            <h1>{t('all_the_resources_title')}</h1>

            <table className="table">
                <thead>
                    <tr>
                        <th>{t('title')}</th>
                        <th>{t('main_objective')}</th>
                        <th>{t('description')}</th>
                        <th>{t('sample_access')}</th>
                        <th>{t('access')}</th>
                    </tr>
                </thead>
                <tbody>
                    {resources.map((resource, index) => (
                        <tr key={index}>
                            <td>{resource.title}</td>
                            <td>{resource.mainObjective}</td>
                            <td>{resource.description}</td>
                            <td>{resource.sampleAccess}</td>
                            <td>
                                <button 
                                    className="button-center"
                                    onClick={() => navigate(resource.link)}
                                >
                                    {renderLockIcon(resource.loginRequired)}
                                    {t('go_to_resource')}
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table><br/><br/>

            <p className="centered-text">{t('join_allvoiced_to_advance_vocal_science')}:</p>
            <button 
                className="navigation-button" 
                onClick={() => navigate('/research-at-all-voiced')}
            >
                {t('research_at_allvoiced')}
            </button><br/>

            <div className="navigation-container">
                <NavigationIcons exclude={['/all-the-resources']} />
            </div>
        </div>
    );
}

export default AllTheResources;