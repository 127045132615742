// AddItemForm.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';

function AddItemForm({ newItem, setNewItem, addItem, placeholder, buttonText, role }) {
  const { t } = useTranslation();

  return (
    <div>
      <input
        type="text"
        value={newItem}
        onChange={(e) => setNewItem(e.target.value)}
        placeholder={t(placeholder)}
        className="input-field"
      />
      <button className="button-center" onClick={() => addItem(newItem, role)}>
        {t(buttonText)}
      </button>
    </div>
  );
}

export default AddItemForm;