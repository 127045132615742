// CAPEVRParent.jsx

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CAPEVRPreview from './cape-vr-views/CAPEVRPreview';
import CAPEVRForm from './cape-vr-views/CAPEVRForm';
import CAPEVRSession from './cape-vr-views/CAPEVRSession'; 
import NavigationIcons from '../views/app-views/NavigationIcons';

function CAPEVRParent() {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState("preview");
  const [selectedSample, setSelectedSample] = useState(null);
  const [submittedData, setSubmittedData] = useState(null);

  // 🔹 Funció per gestionar el canvi d'estats
  const handleNextStep = (nextStep) => {
    setCurrentStep(nextStep);
  };

  return (
    <div className="capevr-container">
      <h1>{t('cape_vr')}</h1>
      
      {/* 🔹 Vista inicial: Selecció de mode (Preview) */}
      {currentStep === "preview" && (
        <CAPEVRPreview
          onSelect={(sample) => {
            setSelectedSample(sample);
            handleNextStep("form"); // 🔹 Va directament al formulari
          }}
          onStartSession={() => handleNextStep("session")} // 🔹 Nova opció per la sessió
        />
      )}

      {/* 🔹 Formulari d'Avaluació (Form) */}
      {currentStep === "form" && (
        <CAPEVRForm
          selectedSample={selectedSample} 
          onSubmit={(data) => {
            setSubmittedData(data);
            handleNextStep("results"); // 🔹 Després del formulari, anem a resultats
          }}
        />
      )}

      {/* 🔹 Sessió interactiva */}
      {currentStep === "session" && (
        <CAPEVRSession selectedSample={selectedSample} />
      )}

      {/* 🔹 Resultats de l'Avaluació */}
      {currentStep === "results" && (
        <div className="capevr-results">
          <h2>{t('results_title')}</h2>
          <p>{t('results_description')}</p>
          <pre>{JSON.stringify(submittedData, null, 2)}</pre>
          <button onClick={() => handleNextStep("feedback")}>{t('continue_to_feedback')}</button>
        </div>
      )}

      {/* 🔹 Feedback després dels resultats */}
      {currentStep === "feedback" && (
        <div className="capevr-feedback">
          <h2>{t('thank_you')}</h2>
          <p>{t('feedback_prompt')}</p>
          <textarea placeholder={t('write_feedback_here')} />
          <button onClick={() => handleNextStep("preview")}>{t('back_to_start')}</button>
        </div>
      )}

      {/* 🔽 Bloc fix de navegació 🔽 */}
      <div className="navigation-container">
        <NavigationIcons />
      </div>
    </div>
  );
}

export default CAPEVRParent;