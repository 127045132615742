// useCategories.js

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function useCategories(initialConcepts) {
  const { t } = useTranslation();

  const [availableConcepts, setAvailableConcepts] = useState(initialConcepts);
  const [categories, setCategories] = useState([
    { id: 'cat1', name: t('category_1_rename_as_needed'), concepts: [] },
    { id: 'cat2', name: t('category_2_rename_as_needed'), concepts: [] },
  ]);

  const handleAddCategory = (name) => {
    if (!name.trim()) return;
    const newCategory = {
      id: `cat${Date.now()}`,
      name: name.trim(),
      concepts: [],
    };
    setCategories((prev) => [...prev, newCategory]);
  };

  const handleDeleteCategory = (categoryId) => {
    setCategories((prev) => prev.filter((cat) => cat.id !== categoryId));
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    setCategories((prevCategories) => {
      const newCategories = [...prevCategories];

      const findCategory = (id) => newCategories.find((cat) => cat.id === id);

      if (source.droppableId === 'availableConcepts') {
        const movedConcept = availableConcepts[source.index];

        setAvailableConcepts((prev) => prev.filter((_, idx) => idx !== source.index));

        const targetCategory = findCategory(destination.droppableId);
        if (targetCategory) {
          targetCategory.concepts.splice(destination.index, 0, movedConcept);
        }
      } else {
        const sourceCategory = findCategory(source.droppableId);
        const destCategory = findCategory(destination.droppableId);

        if (!sourceCategory || !destCategory) return prevCategories;

        const [movedConcept] = sourceCategory.concepts.splice(source.index, 1);

        destCategory.concepts.splice(destination.index, 0, movedConcept);
      }

      return newCategories;
    });
  };

  return {
    availableConcepts,
    setAvailableConcepts,
    categories,
    setCategories,
    handleAddCategory,
    handleDeleteCategory,
    onDragEnd,
  };
}