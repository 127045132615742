// useSubmission.js

import { useState, useCallback } from 'react';
import axios from 'axios';

export function useSubmission(apiUrl, apiEndpoint) {
  const [showFeedback, setShowFeedback] = useState(false);
  const [otherUsersHierarchy, setOtherUsersHierarchy] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchFeedback = useCallback(async (page) => {
    if (!page || page < 1) return; // 🔹 Evitem peticions incorrectes
  
    console.log(`📡 Sol·licitant feedback per a la pàgina: ${page}`);
  
    try {
      const response = await axios.get(`${apiUrl}/${apiEndpoint}/feedback?page=${page}&limit=1`);
  
      console.log("📥 Feedback rebut del servidor:", response.data);
  
      if (!response.data || !response.data.data) {
        console.error("❌ Error: La resposta del servidor no conté dades vàlides.");
        return;
      }
  
      const extractedHierarchies = response.data.data.map(entry => entry.hierarchy || []).flat();
  
      console.log("📌 Hierarchy extreta:", extractedHierarchies);
      console.log("📌 Pàgina actual rebuda del servidor:", response.data.page);
      console.log("📌 Total de pàgines rebudes:", response.data.pages); // 🔹 Canvi de `totalPages` a `pages`
  
      setOtherUsersHierarchy(extractedHierarchies);
      setTotalPages(response.data.pages || 1); // 🔹 Canviem `totalPages` per `pages`
      setCurrentPage(response.data.page || 1); // 🔹 Evitem que sigui `null`
    } catch (error) {
      console.error('❌ Error fetching feedback data:', error);
    }
  }, [apiUrl, apiEndpoint]);   

  const handleSubmit = async (userId, consentGiven, sociodemographicData, items, observations) => {
    if (consentGiven) {
      console.log("📤 Enviant dades crues al servidor:", JSON.stringify({
        userId,
        informedConsent: consentGiven,
        sociodemographicData,
        items, // 🔹 Enviem les dades planes
        observations: observations.trim(),
      }, null, 2));
  
      const dataToSend = {
        userId,
        informedConsent: consentGiven,
        sociodemographicData,
        items, // Dades planes enviades al backend
        observations: observations.trim(),
      };
  
      try {
        await axios.post(`${apiUrl}/${apiEndpoint}`, dataToSend);
        alert('Your results have been successfully submitted.');
      } catch (error) {
        console.error('❌ Error submitting data:', error);
        alert('There was an error submitting your results. Please try again later.');
      }
    }
    setShowFeedback(true);
    fetchFeedback(1);
  };  

  return { showFeedback, otherUsersHierarchy, currentPage, totalPages, fetchFeedback, handleSubmit };
}