// perceptualAttributes.js
// TODO actualitzar la llista de conceptes

const availableConcepts = [
  { id: '1', label: 'accurate_pitch' },
  { id: '2', label: 'adequate_loudness' },
  { id: '3', label: 'adequate_pitch' },
  { id: '4', label: 'aphonic_break' },
  { id: '5', label: 'aphonia' },
  { id: '6', label: 'asthenia' },
  { id: '7', label: 'back_oral_resonance' },
  { id: '8', label: 'breathiness' },
  { id: '9', label: 'diplophonia' },
  { id: '10', label: 'falsetto' },
  { id: '11', label: 'front_oral_resonance' },
  { id: '12', label: 'frequency_range' },
  { id: '13', label: 'fry' },
  { id: '14', label: 'hypernasality' },
  { id: '15', label: 'hyponasality' },
  { id: '16', label: 'lack_of_oral_resonance' },
  { id: '17', label: 'modal_register' },
  { id: '18', label: 'normal_voice_quality' },
  { id: '19', label: 'out_of_tune' },
  { id: '20', label: 'perceived_duration' },
  { id: '21', label: 'pitch_break' },
  { id: '22', label: 'pitch_instability' },
  { id: '23', label: 'pitch_stability' },
  { id: '24', label: 'resonance' },
  { id: '25', label: 'roughness' },
  { id: '26', label: 'spasm' },
  { id: '27', label: 'strain' },
  { id: '28', label: 'too_high' },
  { id: '29', label: 'too_low' },
  { id: '30', label: 'too_loud' },
  { id: '31', label: 'too_soft' },
  { id: '32', label: 'tremor' },
  { id: '33', label: 'wet_gurgly' },
];

export default availableConcepts;
  